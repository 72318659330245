<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:title>
        <h2>Zásilkovna</h2>
        <h1>{{ $t("dashboard.pickup-points") }}</h1>
      </template>

      <template v-slot:right-action>
        <router-link
          :to="{ name: 'account', query: { platform, device } }"
          class="account-circle"
        >
          <account-circle />
        </router-link>
      </template>
    </AppHeader>

    <main>
      <section v-if="isPairingActive">
        <router-link
          v-if="isMobile"
          class="btn btn-secondary btn-red"
          :to="{
            name: 'packets-receive-process',
            params: { batchId: 0 },
            query: { platform, device }
          }"
          ><qrcode-scan class="qrcode" :size="38" />
          <div>
            <h1>{{ $t("dashboard.receive") }}</h1>
            <p>{{ $t("dashboard.scan") }}</p>
          </div></router-link
        >
        <router-link
          class="btn btn-secondary btn-red"
          :to="{ name: 'packet-search', query: { platform, device } }"
          ><img src="../../public/icon-navigation-box@2x.png" alt="" />
          <div>
            <h1>{{ $t("dashboard.packets") }}</h1>
            <p>{{ $t("dashboard.release-packets") }}</p>
          </div></router-link
        >
      </section>
      <DashboardError v-else />
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AppHeader from "@/components/AppHeader";
import DashboardError from "@/components/DashboardError";
import QrcodeScan from "vue-material-design-icons/QrcodeScan.vue";
import AccountCircle from "vue-material-design-icons/AccountCircle.vue";
import { getParams } from "@/mixins/platform-params.js";

export default {
  name: "Dashboard",
  mixins: [getParams],
  components: { AppHeader, QrcodeScan, AccountCircle, DashboardError },
  computed: {
    ...mapGetters("auth", ["isPairingActive"])
  }
};
</script>

<style lang="scss" scoped>
section {
  max-width: 280px;
  margin: auto;

  a {
    &:first-child {
      margin: 80px 0 20px;
    }

    .qrcode {
      margin-right: 9px;
    }

    img {
      height: 43px;
      margin: 0;
      margin-right: 8px;
    }

    h1 {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 1.79px;
    }
  }
}
</style>
