<template>
  <div>
    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section class="error">
        <div class="red text-center">
          <close class="close" :size="160" />
          <h1>{{ $t("dashboard.caution") }}</h1>
          <h2>{{ $t("dashboard.attention-title") }}</h2>
          <p v-html="$t('dashboard.device-server')"></p>
        </div>
      </section>
    </main>
    <footer ref="footer">
      <div class="container">
        <a
          href="#"
          class="btn btn-primary btn-green"
          @click.prevent="logoutFromApp"
          >{{ $t("dashboard.login-again") }}</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { setActualFooterHeight } from "@/mixins/footer";
import Close from "vue-material-design-icons/Close.vue";

export default {
  name: "DashboardError",
  mixins: [setActualFooterHeight],
  components: {
    Close
  },

  methods: {
    ...mapActions("auth", ["logout"]),

    logoutFromApp() {
      this.logout();
      this.$router.replace({
        name: "login",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  },

  mounted() {
    this.setActualFooterHeight(); // Set footer height after data fetch
  }
};
</script>

<style lang="scss" scoped>
.error {
  padding: 0 3rem;
  margin-top: 2.5rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  h2 {
    font-size: 1rem;
  }

  p {
    color: $light-grey;
  }
}

footer {
  a {
    margin-bottom: 10px;

    &.a-black:last-child {
      display: inline-block;
      margin: 16px 0 26px;
    }
  }
}

.close {
  svg {
    margin: -20px 0 -20px;
  }
}
</style>
